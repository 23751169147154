import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { Link } from 'gatsby'

import { Section, SectionProps } from '../Section'

import * as classes from './box-links.module.scss'

interface Props extends SectionProps {
  links: ReadonlyArray<{
    to: string
    name: string
  }>
}

export const BoxLinks = ({ links, ...props }: Props) => (
  <Section className={classes.boxes} {...props}>
    <Row className={classes.smallPadding}>
      {links.map(({ to, name }) => (
        <Col xs={12} sm={12} md={4} lg={4} key={to}>
          <Link to={to}>
            <div className={classes.box}>
              <span>{name}</span>
            </div>
          </Link>
        </Col>
      ))}
    </Row>
  </Section>
)
