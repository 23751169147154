// extracted by mini-css-extract-plugin
export var arenaImage = "ticketing-module--arenaImage--wd0Vv";
export var button = "ticketing-module--button--Pr7+3";
export var buttonContainer = "ticketing-module--button-container--Ksv5o";
export var buttonHeaderContainer = "ticketing-module--buttonHeaderContainer--lmSRn";
export var buttonToolbar = "ticketing-module--buttonToolbar--2M3GQ";
export var column = "ticketing-module--column--a9NlP";
export var marginBottom = "ticketing-module--margin-bottom--+tU7i";
export var match = "ticketing-module--match--JiZuL";
export var section = "ticketing-module--section--bOr8g";
export var title = "ticketing-module--title--Dv-Wu";
export var vs = "ticketing-module--vs--1GLwC";